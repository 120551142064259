.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.mainContent {
  display: flex;
  height: 100vh;
  margin-left: 20%;
  width: calc(100% - 20%);
  flex-direction: column;
}

.headerContainer {
  height: 8%;
  width:100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
}

.clickToAsk{
  text-align: center;
  margin-top: 30px;
}

@media only screen and (max-width: 1026px) and (min-width: 768px) {
 .container {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .mainContent {
    display: flex;
    height: 100%;
    width: 100%;
    margin-left: 0%;
  }

  .headerContainer {
    height: 7%;
  }
}


@media only screen and (max-width: 767px) {
  .container {
    height: 94%;
    width: 100%;
  }
  
  .mainContent {
    display: flex;
    height: 100%;
    width: 100%;
    margin-left: 0%;
    }

  .headerContainer {
    height: 7%;
    position: fixed;
    min-height: 52px;
    top: 55px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .history{
    width: 50px;
    height: 40px;
    /* background: #EBEDEF; */
    border: 1px solid #662ec7;
    background: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
}
