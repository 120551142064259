/* App Container */
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Importing the Metropolis font */
@font-face {
  font-style: normal;
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Regular.otf') format('opentype');
}

@font-face {
  font-style: normal;
  font-family: 'Metropolis Bold';
  src: url('../fonts/Metropolis-Bold.otf') format('opentype');
}

@font-face {
  font-style: normal;
  font-family: 'Metropolis SemiBold';
  src: url('../fonts/Metropolis-SemiBold.otf') format('opentype');
}


/* Common Font Styles */
h1, h2, h3, h4, p {
  padding:0;
  margin-block-end: 1rem;
  margin-block-start: 1rem;
}

/* letter-spacing: 2px; */


/* Headings Text */
h1 {
  font-size: 72px;
  font-weight: bold;
  font-family: 'Metropolis SemiBold', sans-serif;
}
h2 {
  padding:0;
  font-size: 38px;
  font-family: 'Metropolis SemiBold', sans-serif;

}
h3{
  font-size: 36px;
  font-family: 'Metropolis SemiBold', sans-serif;
}
h4{
  /* margin: 5px; */
  font-size: 24px;
  font-family: 'Metropolis', sans-serif;
}

/* Paragraph Text */
p {
  font-size: 16px;
  margin-bottom: 1rem;
  font-family: 'Metropolis', sans-serif;
}


/* Font Sizes for Mobile Devices */
@media (max-width: 768px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 16px;
  }
  p {
    font-size: 12px;
  }
}




@media only screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    
  }
}