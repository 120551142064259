.container {
  display: flex;
  height: 100vh;
  width: 100%;
  background: #fff;
  flex-direction: column;
  align-items: center;
}

.testInfo {
  height: 8%;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center; 
  align-items: center;
}

.timer{
  position: absolute;
  left: 10%;
}

.test {
  height: 92%;
  width: 95%;
  background: #E8f5FE;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  margin-top: 10px;

}


.question {
  margin-bottom: 30px;
  padding: 15px; 
  border-radius: 10px; 
  background: transparent;
  width: 95%;
  margin-left: 30px;
  margin-top: 30px;
}

.options {
  margin-top: 10px;
}


.option {
  display: flex;
  margin-bottom: 10px;
  padding: 10px; 
  border-radius: 10px;
  transition: background-color 0.2s; 
  width: 80%;
  align-items: center;

}



.option:hover {
  background-color: #1b97f53e;
  width: 80%;
}

.checkBox{
  margin-right: 10px;
  height: 25px;
  width: 25px;
  border-radius: 15px;
}

.navigationButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
}


.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.popup {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 350px;
  padding: 20px;
  max-width: 90%;
  border-radius: 20px;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
}


.heading {
  text-align: center;
  margin-top: 10%;
  color:#1b98f5;
  width: 70%;
  line-height: 40px;
}

.result{
  margin-bottom: 20px;
}

.explanationBox{
  width: 90%;
  background-color: #1b97f53e;
  border-radius: 20px;
  padding: 15px 20px;
}

.explanation{
  font-size: 1.1em;
  font-weight: 500;
}

.hint{
  margin-left: 15px;
}

@media only screen and (max-width: 767px) {
 
  .question {
    margin-left: 0px;
    margin-bottom: 15px;
    margin-top: 0px;
    padding: 15px; 
   
  }

  .questionHeading{
    font-size: 1.2em;
  }
  .popup {
    width: 300px;
    height: 300px;
    border-radius: 20px;
  }

  .heading{
    font-size: 1.7em;
    text-align: center;
    width:90%;
    color: #1b98f5;
  }

  .testInfo {
    height: 15%;
    flex-direction: column-reverse;
  }

  .test{
    height: 92%;
  width: 90%;
    margin-top: 10px;
  }

  .timer{
    position: relative;
    left: 0%;
    font-size: 1.5em;
    margin-top: 20px;
  }


}