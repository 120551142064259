.container {
    display: flex;
    height: 100vh;
    width: 100%;
    background: #fff;
    flex-direction: column;
    align-items: center;
  }

  .testInfo {
    height: 8%;
    width: 100%;
    background: #fff;
    display: flex; 
    justify-content: center; 
    align-items: center;
  }

  .navigationButtons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%; 
  }
  
  
  @media only screen and (max-width: 767px) {
 
    .exitButton{
      font-size: 1.5em;
    }
  }