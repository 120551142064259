.headerContainer {
  display: flex;
  height: 6%;
  width: 100%;
  min-height: 53px;
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid #EBEDEF;
  /* background: #eeeff0; */
  background: #f3eaff;
  
}

.back {
  width:20%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.backIcon {
  transform: rotate(-45deg);
  font-size: 1.5rem; 
}

.headerContent {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding-right: 5%;
  margin-top: 1px;
}



/* .headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8%; 
  min-height: 70px; 
  width: 100%;
  position: fixed;
  z-index: 100;
}

.header {
  min-height: 55px; 
  height: 55px; 
  width: 97%;
  background-color: var(--menuShadeColor);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  border-radius: 15px;
  transition: border-radius 0.3s ease;
}

.drawerOpen .header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.menuButton {
  border: none;
  color: var(--menuColor);
  cursor: pointer;
  font-weight: 500;
  border-radius: 8px;
  padding: 5px 15px;
  font-size: 2em;
  margin-left: 5px;
  background: transparent;
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: flex;
  padding-bottom: 9px;
  outline: none; 
}

.menuButtonActive {
  transform: rotate(90deg);
  opacity: 0.8; 
  outline: none; 
  background-color: transparent;
}

.brand {
  display: flex;
  margin-left: 12%;
  justify-content: center;
  align-items: center;
}


.drawerOpen {
  transition: background-color 0.3s ease;
} */
