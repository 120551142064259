.container {
    display: flex;
    height: 100vh;
    margin-left: 20%;
    width: calc(100% - 20%);
    background: #fff;
  }

  .mainHavingIssue{
    width: 83vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }


  .boxContainer {
    width: 60%;
    height: 35%;
    padding: 40px;
    margin-top: 40px;
    border-radius: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center; 
    background-color: #e0f7e0;
  }


.text{
      font-size: 1.8em;
      line-height: 1.3em;
      font-weight: 600;
      color: #444444;

  }

@media only screen and (max-width: 1026px) and (min-width: 768px) {
    .container {
      height: 93%;
      width: 100%;
      margin-left: 0%;
    }
     
    .mainHavingIssue{
      width: 100%;
      height: 100%;
    }
   }
   
@media only screen and (max-width: 767px) {
  .container {
    height: 100vh;
    width: 100vw;
    margin-left: 0%;
    position: fixed;
   }
     .mainHavingIssue{
      margin-top: 100px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
  }
   
   .heading {
    position: fixed;
    left: 44%;
    font-size: 1.3em;
    color: var(--menuColor);
    }

    .boxContainer {
      width: 81%;
      height: auto;
      padding: 20px 25px;
      margin-top: 0px;
      border-radius: 30px;
 
    }

    .text{
      
        text-align: center;
        margin-bottom: 0px;
        font-size: 1.3em;
        width: 100%;
 

    }
   }

  