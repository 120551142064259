.container {
  height: 100vh;
  display: flex;
  overflow-y: auto;
  margin-left: 20%;
  width: calc(100% - 20%);
}

.mainPage {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.allTests {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
  
.testContainer {
  width: 85%;
  height: auto;
  display: flex;
  text-align: center;
  padding: 30px 50px;
  border-radius: 30px;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #E8f5FE;
}

.topicName {
  margin: 10px 0;
  font-size: 1.5em;
  font-weight: 600;
  text-align: start;
}

.testInfo {
  width: 40%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  flex-direction: row;
}

.infoRow {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  
}


@media only screen and (max-width: 1026px) and (min-width: 768px) {
  .container {
    height: 93%;
    width: 100%;
    margin-left: 0%;
  }
  

   
 }
 
 
 @media only screen and (max-width: 767px) {
  .container {
    height: 100vh;
    width: 100vw;
    margin-left: 0%;
    position: fixed;
   }
   .mainPage {
    margin-top: 70px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
  }
   
   .heading {
    position: fixed;
    left: 35%;
    /* margin-bottom: 20px; */
    font-size: 1.3em;
    color: var(--menuColor);
  }

  .testContainer {
    width: 79%;
      display: flex;
      padding: 20px 25px;
      border-radius: 20px;
      flex-direction: column;

  }

  .topicName {
    font-size: 1.6em;
      font-weight: 600;
      margin-bottom: 10px;
      color: #1b98f5;
  }

  .testInfo {
    display: flex;
    width: 85%;
    align-items: flex-start;
    justify-content: flex-start;
 
  }

  .infoText{
    font-size: 1.1em;
      margin-bottom: 5px;
  }

  .infoRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 0px;
    align-items: flex-start;
    justify-content: flex-start;

  }

 }