.menu {
  top: 0;
  left: 0;
  width: 20%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  position: fixed;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
}

.menuSection{
  width: 96%;
  height: 98%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color:var(--menuShadeColor);
}

.menuItem {
  margin: 20px;
  display: flex;
  cursor: pointer;
  font-size: 1.15rem;
  align-items: center;
}

.quick {
  margin-top: 20px;
  font-weight: 650;
  padding-left: 28px;
  font-size: 1.15rem;
  color: var(--menuColor);
}
