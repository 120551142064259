.label {
    background: #1b97f53e;
    padding: 10px 25px;
    border-radius: 10px;
    margin-top: 5px;
    margin-right:30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }