.popupOverlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .popup {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 200px;
    padding: 20px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
  }
  

  .heading{
    text-align: center;
    width:80%;
    color: #1b98f5;
  }

  @media only screen and (max-width: 1026px) and (min-width: 768px) {
   
   }
   
   
   @media only screen and (max-width: 767px) {
    .popup {
      width: 300px;
      height: 200px;
      border-radius: 20px;
    }

    .heading{
      font-size: 1.7em;
      text-align: center;
      width:90%;
      color: #1b98f5;
    }

   }