 .menuItem {
    padding: 10px;
    display: flex;
    cursor: pointer;
    margin-left: 30px;
    margin-bottom: 8px;
    align-items: center;
    padding-left: 15px;
  }
  
  .selectedMenuItem {
    width: 70%;
    padding: 10px;
    display: flex;
    cursor: pointer;
    margin-left: 30px;
    margin-bottom: 8px;
    border-radius: 12px;
    align-items: center;
    padding-left: 15px;
    background-color: var(--menuColor);
  }
  
  .menuItem:hover {
    width: 70%;
    border-radius: 12px;
    background-color: var(--menuHoverColor);
  }
  
  
  .menuText {
    font-size: 17px;
    font-weight: 500;
    color: #121212;
    margin-left: 13px;
  }
  
  .selectedMenuText {
    font-size: 17px;
    color: #fff;
    font-weight: 550;
    margin-left: 13px;
  }
  
  /* Change menu text color on hover */
  .menuItem:hover .menuText {
    color: #fff; /* Change text color to white on hover */
  }
  