.container {
  display: flex;
  height: 100vh;
  margin-left: 20%;
  background: #fff;
  width: calc(100% - 20%);
}

.mockTestPaper {
  width: 83vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-size: 3em;
  font-weight: 640;
  text-align: center;
  margin-bottom: 10px;
}

.subHeading {
  width: 70%;
  font-size: 2em;
  font-weight: 640;
  text-align: center;
}

.formContainer {
  width: 50%;
  height: 45%;
  padding: 20px;
  margin-top: 30px;
  border-radius: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  background-color: #E8f5FE;
  justify-content: center; 
}

.testCreationForm,
.testStep {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dropdownContainer{
  margin-top: -15px;
}

.topicInput {
  width: 70%;
  border: none;
  outline: none;
  padding: 5px 0;
  margin-top: 50px;
  font-weight: 500;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 40px;
  background-color: transparent;
  border-bottom: 2px solid #696969;
}

.selectDropdown {
  width: 65%;
  outline: none;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  border-radius: 10px;
  background-color: #F9FBFC;
  border: 2px solid #696969;
}

.selectedOptions {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-direction: column;
}

.selectedOptions p {
  margin: 5px 0;
  font-size: 1.2em;
  text-align: left;
}

.selectedOptions .subject {
  color: #007bff;
  font-weight: bold;
}

.difficultyLevel {
  color: #333;
  margin-top: 10px;
  font-size: 1.2em;
}

.noOfQuestions {
  color: #333;
  margin-top: 10px;
  font-size: 1.2em;
}

.questionType {
  color: #333;
  margin-top: 10px;
  font-size: 1.2em;
}

.finalSelections {
  width: 70%;
  display: flex;
  color: #333;
  margin-top: 30px;
  font-size: 1.2em;
  font-weight: 500;
  align-items: center;
  flex-direction: column;
}

.testStepContent{
  width:100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.finalSelections p {
  text-align: left;
  margin-bottom: 10px;
}

.finalHeading {
  font-size: 2em;
  font-weight: 640;
  text-align: center;
  margin-bottom: 20px;
}

.navigationButtons {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  
 
}

.stepIndicator {
  color: #333;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1026px) and (min-width: 768px) {
  .container {
    height: 93%;
    width: 100%;
    margin-left: 0%;
  }
  
  .mockTestPaper {
    width: 100%;
    height: 100%;
  }

   
 }
 
 
 @media only screen and (max-width: 767px) {
   .container {
     height: 100vh;
     width: 100vw;
     margin-left: 0%;
     position: fixed;
    }
    
    .mockTestPaper {
      position: fixed;
      width: 100%;
      height: 94%;
      justify-content: flex-start;
      top:70px
  }

  .heading {
    font-size: 1.8em;
    width: 90%;
    margin-bottom: -15px;
  }

  .formContainer {
    width: 82%;
    margin-top: 40px;
    height: auto;
 
  }

  .subHeading {
    width: 90%;
    font-size: 1.5em;
  }
  .topicInput {
    width: 90%;
    margin-top: 20px;
  }

  .selectDropdown {
    width: 90%;
    outline: none;
    padding: 10px;
    margin: 0px 0;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
    background-color: #F9FBFC;
    border: 2px solid #696969;
  }
  .history{
    width: 50px;
    height: 40px;
    /* background: #EBEDEF; */
    border: 1px solid #662ec7;
    background: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    }


 }
