@font-face {
  font-family: 'Metropolis';
  src: url('../../../fonts/Metropolis-Medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

.messageContainer {
  width: 70%;
  height: 10%;
  display: flex;
  margin: 0 auto;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
}

.textareaContainer {
  position: relative;
  flex-grow: 1;
  width: 100%;
}

.textInput {
  border: 1px solid #1b98f5;
  width: 100%;
  resize: none;
  outline: none;
  padding: 15px 50px 15px 25px; /* Add padding for the icon */
  font-size: 17px;
  max-height: 75px;
  min-height: 50px;
  line-height: 1.5;
  text-align: left;
  overflow-y: hidden;
  border-radius: 35px;
  box-sizing: border-box;
  /* border: none; */
  background: #EBEDEF;
  font-family: 'Metropolis', sans-serif;
}

.cameraButton {
  position: absolute; /* Position the icon absolutely */
  top: 50%;
  right: 15px; /* Adjust to the right for end positioning */
  transform: translateY(-50%);
  cursor: pointer;
}

.cameraIcon {
  height: 35px;
  width: 35px; 
}

.sendButton {
  border: none;
  display: flex;
  cursor: pointer;
  min-width: 60px;
  min-height: 60px;
  margin-left: 8px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #EBEDEF;
}

.sendButton:hover {
  background-color: #d1d1d1;
}

.sendButton img {
  width: 20px;
  height: 20px;
}
/* Full-screen crop container overlay */
.cropContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay with opacity for better effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  overflow: hidden;
  backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
}

.cropper {
  width: 90vw;
  height: 75vh;
  max-width: 500px;
  max-height: 500px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.uploadingPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay with opacity */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  color: #fff;
  font-size: 18px;
  font-family: 'Metropolis', sans-serif;
  backdrop-filter: blur(10px);
}





@media only screen and (max-width: 1026px) and (min-width: 768px) {
  .messageContainer {
    height: 10%;
    width: 90%;
  
  }
}

@media only screen and (max-width: 767px) {
  .messageContainer {
    height: 8%;
    min-height: 60px;
    width: 95%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
  }
  .cropper {
    width: 95vw;
    height: 80vh; /* Full height to fit screen */
  }
  
  .cropButton {
    font-size: 14px;
    padding: 10px 20px;
  }

 

}
