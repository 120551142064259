.container {
  display: flex;
  height: 100vh;
  margin-left: 20%;
  width: calc(100% - 20%);
  background: #fff;
}

.allSettings {
  width: 83vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.boxContainer {
  width: 70%;
  height: 60%;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  align-items: center;
  border-radius: 40px;
  background-color: #e8f5fe;
  justify-content: space-around;
  
}

.smallBoxContainer {
  width: 40%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #1b98f5;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.name {
  font-size: 18px;
  color: #e8f5fe;
}

.value {
  font-size: 25px;
  color: #fff;
  margin-top: -15px;
}


@media only screen and (max-width: 1026px) and (min-width: 768px) {
  .container {
    height: 93%;
    width: 100%;
    margin-left: 0%;
  }

  .allSettings {
    width: 100%;
    height: 100%;
  }
   
 }
 
@media only screen and (max-width: 767px) {
   .container {
    height: 100vh;
    width: 100vw;
    margin-left: 0%;
    position: fixed;
   }
   
   .allSettings {
    /* margin-top: 70px; */
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
  }

  .heading {
    position: fixed;
    left: 38%;
    font-size: 1.3em;
    color: var(--menuColor);
  }

  .boxContainer {
    width: 83%;
    height: auto;
    margin-top: 30px;
    padding-top: 30px;
    border-radius: 30px;
    justify-content:center;
    overflow-y: scroll;
    overflow-x: hidden;
    /* flex-grow: 1; */
    
  }

  .smallBoxContainer {
    width: 90%;
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #1b98f5;
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
 }