.subjectSelectorWrapper {
  display: flex;
  position: relative;
  border-radius: 15px;
  align-items: center;
  background: #c7e6fc;
  /* background: #c6cefd; */
  justify-content: center;
  width: calc(81vw - 220px);
  padding:0px 10px;
  /* border: 1px solid #1b98f5; */

}

.arrowButton {
  border: none;
  font-size: 28px;
  cursor: pointer;
  padding: 0 10px;
  color: #1b97f5db;
  background: transparent;
}

.subjectSelectorContainer {
  width: 100%;
  height: 40px;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  /* background-color: #6050dc00; */

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
}

.subject {
  margin: 0 15px;
  flex-shrink: 0;     
  cursor: pointer;
  overflow: hidden;    
  color: #121212;
  max-width: 150px;  
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 10px;
  white-space: nowrap; 
  text-overflow: ellipsis; 
}

.subject:hover {
  color: #065ce5;
}

.selectedSubject {
  color: #121212;
  background-color: rgba(255, 255, 255, 0.95);
}


@media only screen and (max-width: 767px) {
  .subjectSelectorWrapper {
    width: 84%;
    height: 45px;
    outline: none;
  
  }
  .subjectDropdown {
    font-size: 20px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: center;
    font-weight: 545;
    outline: none;

  }

}
