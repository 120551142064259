.chatContainer {
  height: 92%;
  width:100%;
  display: flex;
  flex-direction: column;
}
   
.chats {
  height:90%;
  flex-grow: 1;
  gap: 12px;
  padding: 15px;
  display: flex;
  overflow-y: scroll;
  padding-bottom: 100px;
  flex-direction: column;
  background-color: #fff;
  justify-content: flex-start;
}



.rightChat {
  max-width: 40%;
  color: #121212;
  margin-right: 10%;
  padding: 12px 18px;
  border-radius: 10px;
  align-self: flex-end;
  word-wrap: break-word;
  background-color: #e0f0fc;
}

.leftChat {
  max-width: 60%;
  margin-left: 10%;
  color: #121212;
  padding: 12px 18px;
  border-radius: 10px;
  word-wrap: break-word;
  align-self: flex-start;
  background-color: #eff1f2;

}

/* Add this to your CSS file */
.chatImage {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  border-radius: 8px; /* Optional: for rounded corners */
}

.questionSuggestions{
  display: flex;
  flex-direction: row;
  margin-left: 10%;
}


.animationContainer{
    display:flex;
    flex-direction: column;
    margin-top: 2%;
    align-items: center;
  
}

.clickToAsk{
  margin-top: 50px;
  text-align: center;
}


.chats::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.chats::-webkit-scrollbar-track {
  background: #696969;
  border-radius: 20px;
}

.brandOrganizationLogo {
  border-radius: 25px;
  height: 260px;
  width: 260px;
  opacity: 0.8;

}

.cropContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  overflow: hidden;
  backdrop-filter: blur(10px); 
}

/* For Firefox */
.chats {
  scrollbar-width: thin;
  scrollbar-color: #696969 #f1f1f1;
}

@media only screen and (max-width: 1026px) and (min-width: 768px) {
  .chatContainer {
    height: 92%;
  }
}

@media only screen and (max-width: 767px) {
  .chatContainer {
    height: 92%;
    position: fixed;
    top:110px;
  }
  
  .chats {
    padding-bottom: 140px;

   
  }

  .rightChat{
    margin-right: 1%;
    max-width: 70%;
  }
  
  .leftChat {
    max-width: 80%;
    margin-left: 1%;
  }
  
  .questionSuggestions{
    display: flex;
    max-width: 60%;
    margin-left: 1%;
    flex-direction: column;
  }

  .brandOrganizationLogo{
    height: 150px;
    width: 150px;
  }

  .animationContainer{
    margin-top: 10%;
  }
}
