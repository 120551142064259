.container {
    height: 100vh;
    display: flex;
    overflow-y: auto;
    margin-left: 20%;
    width: calc(100% - 20%);
  }
  
  .mainPage {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media only screen and (max-width: 1026px) and (min-width: 768px) {
    .container {
      height: 92%;
      width: 100%;
      margin-left: 0%;
    }
     
   }
   
@media only screen and (max-width: 767px) {
  .container {
    height: 100vh;
    width: 100vw;
    margin-left: 0%;
    position: fixed;
   }
   .mainPage {
    margin-top: 70px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
  }
   
   .heading {
    position: fixed;
    left: 33%;
    font-size: 1.3em;
    color: var(--menuColor);
  }
     
   }