.onboardingContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

.formContainer {
    width: 50%;
    padding: 40px;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #E8f5FE;
  }

  .heading {
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 60px;
  }

.topicInput {
    width: 70%;
    border: none;
    outline: none;
    padding: 5px 5px;
    font-weight: 500;
    font-size: 1.2em;
    margin-bottom: 50px;
    background-color: transparent;
    border-bottom: 2px solid #696969;
  }

  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(10px);
  }
  
  .popup {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 20px;
    max-width: 90%;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .popupHeading{
    font-size: 1.7em;
    color: var(--menuColor);
  }

  .subjectList {
    margin-top: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .closeButton {
    border: none;
    cursor: pointer;
    background: none;
    font-size: 1.5em;
  }
  
  .subjectRow {
    display: flex;
    margin: 15px 0;
    align-items: center;
    justify-content: space-between;
    background-color: var(--boxColor);
    border-radius: 20px;
    padding: 20px 25px

  }

  .subjectName{
    font-size: 1.5em;
    font-weight: 500;
  }


  @media only screen and (max-width: 1026px) {
    .formContainer {
      width: 70%;
      margin-top: 80px;
    }

    .heading {
      font-size: 2em;
    }

    .popupHeading{
      margin-left: 5px;
    }

    .topicInput {
      width: 95%;
      text-align: center;
    }
    .popup {
      width: 330px;
      border-radius: 20px;
      padding: 20px 15px
    }

    .subjectName{
      font-size: 1.2em;
      font-weight: 500;
    }

    .onboardingContainer {
      justify-content: flex-start;
    }

   
  }