.iconButton {
  display: flex;
  min-width: 56px;
  cursor: pointer;
  min-height: 56px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 15px;
  align-items: center;
  /* background: #dceffd; */
  /* background: #EBEDEF; */
  background:#ffff;
  justify-content: center;
  padding: 0 15px;
  border: 1px solid #662ec7;
}

.iconButton:hover {
  background: #1b97f5ac;
}

.iconButton .name {
  margin-left: 15px;
  font-size: 1.1em;
  color: #333;
  display: inline-block; 
  /* line-height: 1.2;  */
}

/* Ensure flexbox works as expected */
.iconButton {
  align-items: center;
  justify-content: flex-start;  /* This ensures the items are aligned left */
}

/* Responsive adjustments for smaller screens */
@media only screen and (max-width: 767px) {
  .iconButton {
    min-height: 40px;
    min-width: 50px;
  }

  .iconButton .name {
    font-size: 14px;
  }
}
