.onboardingContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

.formContainer {
    width: 50%;
    padding: 40px;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--menuShadeColor);
  }

  .heading {
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 60px;
    line-height:40px ;
  }

.topicInput {
    width: 70%;
    border: none;
    outline: none;
    padding: 5px 5px;
    font-weight: 500;
    font-size: 1.2em;
    margin-bottom: 50px;
    background-color: transparent;
    border-bottom: 2px solid #696969;
  }

  .selectDropdown {
    width: 70%;
    outline: none;
    padding: 12px;
    margin: 10px 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.2em;
    min-height: 60px;
    text-align: center;
    border-radius: 10px;
    background-color: #F9FBFC;
    border: 2px solid #696969;
  }

  @media only screen and (max-width: 1026px) {
    .formContainer {
      width: 70%;
    }

    .heading {
      font-size: 2em;
    }

    .topicInput {
      width: 95%;
      text-align: center;
    }

    .codeScreen {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .onboardingContainer {
      justify-content: flex-start;
    }

    .selectDropdown {
      width: 100%;
    }
   
  }


