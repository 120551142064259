.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  
  .popup{
    width: 400px;
    padding: 0px 20px;
    max-width: 90%;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }
  
  .closeButton {
    border: none;
    cursor: pointer;
    background: none;
    font-size: 1.5em;
  }
  
  .subjectList {
    margin-top: 20px;
  }
  
  .subjectRow {
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: space-between;
  }
  
  .addButton {
    border: none;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #007bff;
    transition: background-color 0.3s ease;
  }
  
  .addButton:hover {
    background-color: #0056b3;
  }
  
  .removeButton {
    border: none;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #ff4d4d; 
    transition: background-color 0.3s ease;
  }
  
  .removeButton:hover {
    background-color: #cc0000;
  }
  
  .popup h2 {
    margin: 0;
  }
  
  .popupContainer {
    display: flex;
    flex-direction: column;
  }
  
  .subjectItem {
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
  }
  
  .subjectItem:last-child {
    border-bottom: none;
  }
  
  @media only screen and (max-width: 767px) {
    .popup{
      width: 300px;
      max-height: 500px;
      overflow-y: scroll;

    }

    .heading{
      font-size: 1.5em;
    }
  }