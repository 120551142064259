/* .progressBar {
    height: 35px;
    width: 50%;
    margin-top: 8px;
    border-radius: 20px;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aqua;
  }
  
  .progress {
    height: 35px;
    position: absolute;
    left: 0;
    border-radius: 25px;
  }
  
  .percentageText {
    position: absolute;
  } */

  .progressBar {
    height: 35px;
    width: 50%;
    margin-top: 40px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBEDEF;
    position: relative;
  }
  
  .progress {
    height: 35px;
    position: absolute;
    left: 0;
    border-radius: 25px;
  }
  
  .percentageText {
    position: absolute;
    z-index: 1;
    color: black;
    font-weight: 500;
  }
  
  @media only screen and (max-width: 767px) {
    .progressBar {
      height: 35px;
      width: 90%;
      margin-top: 40px;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #32cd3298;
      position: relative;
    }
  
     
   }