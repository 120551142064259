.container {
    display: flex;
    height: 100vh;
    width: 100%;
    background: #fff;
    flex-direction: column;
    align-items: center;
  }

  .pdfViewerContainer{
    display: flex;
    width: 95%;
    background: #000;
    height: 90%;
    /* border-top-right-radius: 30px;
    border-top-left-radius: 30px; */
    margin-top: 10px;
}

.pdfViewer{
    width: 100%;
    height: 100%;
    /* border-top-right-radius: 30px;
    border-top-left-radius: 30px; */
  }