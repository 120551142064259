.container {
    display: flex;
    padding-left: 28px;
    padding-top: 15px;;
    margin-bottom: 30px;
    align-items: center;
  }
  
  .logo {
    width: 55px;
    height: 55px;
    margin-right: 20px;
    border-radius: 10px;

  }
  
  .brandText {
    color: black;
    font-size: 1.7rem;
    padding-top: 2px;
  }

  .brandOrganization{
    font-size: 0.8em;
    margin-top: -8px;
  }
  