.container {
  display: flex;
  height: 100vh;
  overflow-y: auto;
  margin-left: 20%;
  width: calc(100% - 20%);
}

.mainPage {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.heading {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.questionItem {
  width: 85%;
  display: flex;
  margin: 12px 0;
  padding: 30px 50px;
  border-radius: 30px;
  align-items: center;
  background-color: #E8f5FE;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subjectContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.subjectText {
  font-size: 1em;
  color: #333;
  margin-right: 10px;
}

.importantText {
  font-size: 1em;
  color: #1b98f5;
  font-weight: bold;
}

.questionText {
  font-size: 1.3em;
  font-weight: 550;
  color: #121212;
}




.noQuestionsText {
  color: #666;
  font-size: 1.5em;
}

@media only screen and (max-width: 1026px) and (min-width: 768px) {
  .container {
    height: 93%;
    width: 100%;
    margin-left: 0%;
  }
  
   .mainPage {
     flex-grow: 1;
     display: flex;
     align-items: center;
     flex-direction: column;
     
   }
 }
 
 
 @media only screen and (max-width: 767px) {
  .container {
    height: 100vh;
    width: 100vw;
    margin-left: 0%;
    position: fixed;
   }
   .mainPage {
    margin-top: 70px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
  }
   
   .heading {
    position: fixed;
    left: 33%;
    margin-bottom: 20px;
    font-size: 1.3em;
    color: var(--menuColor);
  }

    .questionItem {
      width: 79%;
      display: flex;
      padding: 20px 25px;
      border-radius: 20px;
      flex-direction: column;
      align-items: start;
    }

    .subjectText {
      font-size: 1.1em;
      margin-bottom: 25px;
    }
    
    .importantText {
      font-size: 1.1em;
      margin-bottom: 25px;
    }
    
    .questionText {
      font-size: 1.6em;
      font-weight: 600;
      margin-bottom: 0px;
      color: #1b98f5;
    }
 }