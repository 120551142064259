.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #092eff;
}

.header {
  height: 26%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

/* Wrapper for the Lottie animation */
.lottieBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottieBackground > div {
  width: 100%;
  height: 100%;
  transform: scale(2.5); /* Scale to ensure it fills the width */
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.leftHeader {
  position: relative;
  z-index: 2; /* Ensure it's above the Lottie animation */
  height: 100%;
  width: 70%;
  padding-left: 15px;
  border-bottom-left-radius: 35px;
}

.rightHeader {
  position: relative;
  z-index: 2; /* Ensure it's above the Lottie animation */
  height: 100%;
  width: 38%;
  border-bottom-right-radius: 35px;
}

.heading {
  color: #d2edff;
  font-size: 1.6em;
  line-height: 35px;
  margin-top: 10px;
}

.body {
  height: 74%;
  width: 100%;
  background-color: #c6cefd;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  top: -25px;
  z-index: 3; 
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden; 
  padding-bottom: 50px;
}

.titleName{
  color:#1829a0;
  margin-bottom: 25px;
  opacity: 0.8;
}


.horizontalToolContainer {
  height: 80px;
  display: flex;
  align-items: center;
  width: 85%;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 15px;
  /* cursor: pointer; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row; 
}


.boxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28%;
  height: 100%;
  margin-left: -2px;
  
  }
  
.box{
    border-radius: 15px;
    height: 70px;
    width:70px;
    display: flex;
    align-items: center;
    justify-content: center;

}


.toolDescriptionContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-left: 10px;
}

.name {
  font-size: 1.2em;
  margin: 0;
  font-weight: bold;
  color: #333;
  margin-top: 18px;
  margin-bottom: 3px;
}

.description {
  font-size: 0.9em;
  margin-top: 5px;
  color: #666;
  line-height: 18px;
}

.items{
  /* background: #000; */
  width: 100%;
}

.quick {
  color: var(--menuColor);
  margin-top: 40px;
  font-weight: 650;
  padding-left: 20px;
  font-size: 1.15rem;
}