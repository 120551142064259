.onboardingContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.formContainer {
  width: 50%;
  padding: 40px;
  margin-top: 20px;
  border-radius: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #E8f5FE;
}

/* Heading Style */
.heading {
  font-size: 2.5em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 60px;
}

/* Input Fields */
.topicInput {
  width: 70%;
  border: none;
  outline: none;
  padding: 5px 5px;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 50px;
  background-color: transparent;
  border-bottom: 2px solid #696969;
}

.selectDropdown {
  width: 70%;
  outline: none;
  padding: 12px;
  margin: 10px 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.2em;
  min-height: 60px;
  text-align: center;
  border-radius: 10px;
  background-color: #F9FBFC;
  border: 2px solid #696969;
}

/* Navigation Buttons */
.navigationButtons {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
}

.backButton,
.nextButton {
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 500;
  margin-top: 20px;
  border-radius: 8px;
  padding: 15px 80px;
  background-color: #007bff;
}

.backButton {
  background-color: #888;
}

.nextButton.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.nextButton:hover,
.backButton:hover {
  background-color: #007BBB;
}

/* Mobile Adjustments */
@media only screen and (max-width: 767px) {
  .formContainer {
    width: 82%;
    padding: 20px;
  }

  .heading {
    font-size: 1.6em;
    margin-bottom: 40px;
  }

  .topicInput, .selectDropdown {
    width: 85%;
    font-size: 0.9em;
    min-height: 35px;
    
  }
 

  .navigationButtons {
    width: 100%;
    gap: 5px;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .backButton,
  .nextButton {
    padding: 12px 50px;

  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .formContainer {
    width: 75%; 
    padding: 30px;
  }

  .heading {
    font-size: 2em;
    margin-bottom: 50px;
  }

  .topicInput, .selectDropdown {
    width: 80%;
    font-size: 1.1em;
    min-height: 50px; 
  }

  .navigationButtons {
    width: 100%;
  }

  .backButton,
  .nextButton {
    padding: 14px 70px; 
  }
}