.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
  }
  
  .mainContent {
    display: flex;
    height: 100vh;
    margin-left: 20%;
    width: calc(100% - 20%);
    flex-direction: column;
    background: #fff;
  }
  
  .header {
    height: 9%;
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    background-color: #fff;
    padding: 0 20px;
    position: relative;
  }
  
  
  .heading {
    position: absolute;
    left: 45%;
    transform: translateX(-45%);
    font-size: 1.7em;
    font-weight: 645;
    text-align: center;
  }
  

  @media only screen and (max-width: 1026px) and (min-width: 768px) {
    .container {
       display: flex;
       height: 100%;
       width: 100%;
       }
   
       .mainContent {
         display: flex;
         height: 100%;
         width: 100%;
         margin-left: 0%;
         }
   }
   
   
   @media only screen and (max-width: 767px) {
     .container {
       height: 100vh;
       width: 100vw;
     }
     
     .mainContent {
       display: flex;
       height: 100%;
       width: 100%;
       margin-left: 0%;
       align-items: center;
       background-color: var(--menuShadeColor);
       }
       

      .heading {
        position: relative;
        font-size: 1.4em;
        font-weight: 645;
        text-align: center;
        width: 100%;
        
        
      }



      .chatSection{
        display: flex;
        position: fixed;
        top: -50px;
      }

    
   }